<template>
  <div class="users-stats">
    <div class="begin bg-white p-6 rounded shadow">
      <div class="title text-xl">{{ $t('usersMenu') }}</div>
      <div class="chart">
        <apexcharts type="line" height="350" :options="chartOptions" :series="series"></apexcharts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'users-stats',
  components: { apexcharts: VueApexCharts },
  props: {
    stat: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      series: [
        {
          name: this.$t('anonymize'),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: this.$t('authenticate'),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: this.$t('professional'),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        colors: ['#B28B67', '#FDC706', '#2B99C4'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [
            this.$t('january'),
            this.$t('february'),
            this.$t('march'),
            this.$t('april'),
            this.$t('may'),
            this.$t('june'),
            this.$t('july'),
            this.$t('august'),
            this.$t('september'),
            this.$t('october'),
            this.$t('november'),
            this.$t('december')
          ]
        },
        yaxis: {
          min: 0,
          max: 100
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        }
      }
    }
  },
  created () {
    for (const i in this.stat.date) {
      this.series[0].data[i] = this.stat.date[i].anonymous
      this.series[1].data[i] = this.stat.date[i].authentificate
      this.series[2].data[i] = this.stat.date[i].profesionnel
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
</style>
