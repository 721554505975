<template>
  <div class="characteristic">
    <progress-bar size="medium" :bar-color="color" :val="progression" :bar-border-radius="10"></progress-bar>
  </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress'
export default {
  name: 'characteristic',
  components: {
    ProgressBar
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    progression: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
</style>
