<template>
  <div class="mastered-themes">
    <div class="begin p-6 rounded shadow bg-white">
      <div class="title text-black pb-4 border-b">
        {{ $t('masteredThemes') }}
      </div><br>
      <div class="line">
        <div class="gender-bloc mt-6" v-for="(item, index) in stat.evaluation" :key="index">
          <div class="titre mb-1">{{ item.title }}</div>
          <prog-bar
            :progression="(item.evaluation * 100) / stat.users"
            :color="color[index]"
          />
        </div><br>

      </div>
    </div>
  </div>
</template>

<script>
import progBar from './progBar'
export default {
  name: 'mastered-themes',
  components: {
    progBar
  },
  props: {
    stat: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      color: ['#FDC706', '#B28B67', '#C43A2B', '#78b76e', '#2b99c4', '#C43A2B']
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .begin {
    width: 23rem;
    height: 28rem;
  }
  .gender-grad {
    background: $pass_violet;
  }
  .hiv-grad {
    background: $pass_indigo;
  }
  .sex-grad {
    background: $pass_green;
  }
  .cont-grad {
    background: $pass_orange;
  }
</style>
