<template>
  <div class="geographic-zone">
    <div class="begin p-6 rounded shadow bg-white">
      <div class="title text-black pb-4 border-b">
        {{ $t('geographicAreas') }}
      </div><br>
      <div class="line">
        <div class="hello" id="chartdiv" ref="chartdiv">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
// eslint-disable-next-line camelcase
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/beninLow'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'geographic-zone',
  props: {
    stat: Object
  },
  data () {
    return {
      departments: []
    }
  },
  created () {
    this.getDepartmentStat()
  },
  beforeDestroy () {
    if (this.map) {
      this.map.dispose()
    }
  },
  methods: {
    showMapStat () {
      const vm = this
      const map = am4core.create('chartdiv', am4maps.MapChart)
      // eslint-disable-next-line camelcase
      map.geodata = am4geodata_worldLow
      map.projection = new am4maps.projections.Miller()
      const polygonSeries = map.series.push(new am4maps.MapPolygonSeries())
      console.log(polygonSeries)
      polygonSeries.useGeodata = true
      const polygonTemplate = polygonSeries.mapPolygons.template
      polygonSeries.data = vm.departments
      polygonTemplate.tooltipText = '{name}: {count}'
      polygonTemplate.fill = am4core.color('#B9B9B9')
      polygonTemplate.propertyFields.fill = 'fill'

      // Create hover state and set alternative fill color
      const hs = polygonTemplate.states.create('hover')
      hs.properties.fill = am4core.color('#0E6BA8')
    },
    getDepartmentStat () {
      http.post(apiRoute.baseURL + apiRoute.getAllStat, {}, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          console.log(response)
          this.departments = response.departement
          for (let i = 0; i < this.departments.length; i++) {
            if (this.departments[i].count > 0) {
              this.departments[i].fill = am4core.color('#0E6BA8')
            }
            if (this.departments[i].name.toLowerCase() === 'zou') {
              this.departments[i].id = 'BJ-ZO'
            }
            if (this.departments[i].name.toLowerCase() === 'plateau') {
              this.departments[i].id = 'BJ-PL'
            }
            if (this.departments[i].name.toLowerCase() === 'oueme') {
              this.departments[i].id = 'BJ-OU'
            }
            if (this.departments[i].name.toLowerCase() === 'littoral') {
              this.departments[i].id = 'BJ-LI'
            }
            if (this.departments[i].name.toLowerCase() === 'couffo') {
              this.departments[i].id = 'BJ-KO'
            }
            if (this.departments[i].name.toLowerCase() === 'donga') {
              this.departments[i].id = 'BJ-DO'
            }
            if (this.departments[i].name.toLowerCase() === 'collines') {
              this.departments[i].id = 'BJ-CO'
            }
            if (this.departments[i].name.toLowerCase() === 'borgou') {
              this.departments[i].id = 'BJ-BO'
            }
            if (this.departments[i].name.toLowerCase() === 'atacora') {
              this.departments[i].id = 'BJ-AK'
            }
            if (this.departments[i].name.toLowerCase() === 'alibori') {
              this.departments[i].id = 'BJ-AL'
            }
            if (this.departments[i].name.toLowerCase() === 'mono') {
              this.departments[i].id = 'BJ-MO'
            }
            if (this.departments[i].name.toLowerCase() === 'atlantique') {
              this.departments[i].id = 'BJ-AQ'
            }
          }
          this.showMapStat()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .begin {
    width: 23rem;
    height: 28rem;
  }
  .hello {
    width: 100%;
    height: 300px;
  }
</style>
