<template>
  <div class="number-stats">
    <div class="begin flex items-center justify-between">
      <div class="bloc bg-white rounded pt-6 pb-10 pl-6 flex items-center shadow">
        <div class="image mr-4">
          <svg-icon
            name="ic_allUser"
            original
            class="w-12 h-12"
          />
        </div>
        <div class="stats">
          <div class="number text-2xl font-semibold" v-if="stat !== null">{{ stat.users }}</div>
          <div class="name text-black">{{ $t('userStats') }}</div>
        </div>
      </div>
      <div class="bloc bg-white rounded pt-6 pb-10 pl-6 flex items-center shadow">
        <div class="image mr-4">
          <svg-icon
            name="ic_profession"
            original
            class="w-12 h-12"
          />
        </div>
        <div class="stats">
          <div class="number text-2xl font-semibold" v-if="stat !== null">{{ stat.ssr }}</div>
          <div class="name text-black">{{ $t('pointsMenu') }}</div>
        </div>
      </div>
      <div class="bloc bg-white rounded pt-6 pb-10 pl-6 flex items-center shadow">
        <div class="image mr-4">
          <svg-icon
            name="ic_pro"
            original
            class="w-12 h-12"
          />
        </div>
        <div class="stats">
          <div class="number text-2xl font-semibold" v-if="stat !== null">{{ stat.article }}</div>
          <div class="name text-black flex items-center">
            <div class="mr-4">{{ $t('newContentStats') }}</div>
            <svg-icon
              name="ic_more-info"
              original
              class="w-4 h-4 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'number-stats',
  props: {
    stat: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .number {
    color: $pass_marron;
  }
  .bloc {
    width: 25rem;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
</style>
