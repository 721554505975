<template>
  <div class="characteristic">
    <div class="begin p-6 rounded shadow bg-white">
      <div class="title text-black pb-4 border-b">
        {{ $t('characteristics') }}
      </div><br>
      <div v-if="isLoader" class="loader text-center py-4">
        <clip-loader color="#B28B67"></clip-loader>
      </div>
      <div class="line">
        <div class="women-bloc">
          <div class="titre mb-1">{{ $t('women') }}</div>
          <prog-bar
            :progression="(stat.usersSexe.woman * 100) / stat.users"
            :color="womenColor"
          />
        </div><br>

        <div class="men-bloc">
          <div class="titre mb-1">{{ $t('men') }}</div>
          <prog-bar
            :progression="(stat.usersSexe.man * 100) / stat.users"
            :color="menColor"
          />
        </div><br>

<!--        <div class="men-bloc mb-4">
          <div class="titre mb-1">Non spécifié</div>
          <prog-bar
            :progression="(stat.usersSexe.other * 100) / stat.users"
            :color="ortherColor"
          />
        </div><br>-->

        <div class="age-bloc">
          <div class="titre mb-1">{{ 10 + ' ' + $t('to') + ' ' + 15 + ' ' + $t('old') }}</div>
          <prog-bar
            :progression="(stat.old.first * 100) / stat.users"
            :color="ageColor"
          />
        </div><br>

        <div class="age-bloc">
          <div class="titre mb-1">{{ 16 + ' ' + $t('to') + ' ' + 19 + ' ' + $t('old') }}</div>
          <prog-bar
            :progression="(stat.old.second * 100) / stat.users"
            :color="ageColor"
          />
        </div><br>

        <div class="age-bloc">
          <div class="titre mb-1">{{ 20 + ' ' + $t('to') + ' ' + 25 + ' ' + $t('old') }}</div>
          <prog-bar
            :progression="(stat.old.tree * 100) / stat.users"
            :color="ageColor"
          />
        </div><br>

        <div class="age-bloc">
          <div class="titre mb-1">{{ $t('moreThan') + ' ' + 25 + ' ' + $t('old') }}</div>
          <prog-bar
            :progression="(stat.old.four * 100) / stat.users"
            :color="ageColor"
          />
        </div><br>

<!--        <div class="age-bloc">
          <div class="titre mb-1">Non spécifié</div>
          <prog-bar
            :progression="(stat.old.any * 100) / stat.users"
            :color="ortherColor"
          />
        </div><br>-->
      </div>
    </div>
  </div>
</template>

<script>
import progBar from './progBar'
import ClipLoader from 'vue-spinner/src/ScaleLoader'
export default {
  name: 'characteristic',
  components: {
    ClipLoader,
    progBar
  },
  props: {
    isLoader: Boolean,
    stat: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      womenColor: '#FDC706',
      menColor: '#B28B67',
      ageColor: '#C43A2B',
      ortherColor: '#2b99c4'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .begin {
    width: 23rem;
    height: 28rem;
  }
  .wom-grad {
    background: $pass_yellow;
  }
  .men-grad {
    background: $pass_marron;
  }
  .age-grad {
    background: $pass_red;
  }
</style>
