<template>
  <div class="dashboard">
    <div class="begin pb-12">
      <div class="title text-black font-semibold text-lg">
        {{ $t('dashboardMenu') }}
      </div><br>
      <div class="header">
        <number-stats :stat="stat"/>
      </div><br>
      <div class="body">
        <users-stats :stat="stat" v-if="stat !== null"/>
      </div><br>
      <div class="footer flex justify-between">
        <div class="carac mr-4">
          <characteristic :is-loader="loader" :stat="stat"/>
        </div>
        <div class="carac mr-4">
          <geographic-zone :stat="stat"/>
        </div>
        <div class="carac">
          <mastered-themes :stat="stat"/>
        </div>
      </div>
    </div>
<!--    <change-password
      v-if="$store.getters.userInfos.firstLogin === true"
      @updateSuccess="updateSuccess"
    />-->
    <success-add
      v-if="successAdd"
      :message="$t('successPasswordChange')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import NumberStats from '@/components/helper/dashboard/number-stats'
import UsersStats from '@/components/helper/dashboard/users-stats'
import Characteristic from '@/components/helper/dashboard/characteristic'
import MasteredThemes from '@/components/helper/dashboard/mastered-themes'
import GeographicZone from '@/components/helper/dashboard/geographic-zone'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import SuccessAdd from '../../components/helper/success-add'
export default {
  name: 'index',
  components: { SuccessAdd, GeographicZone, MasteredThemes, Characteristic, UsersStats, NumberStats },
  data () {
    return {
      stat: null,
      loader: true,
      changePassword: true,
      successAdd: false
    }
  },
  created () {
    this.getAllStat()
  },
  methods: {
    logOutStore () {
      this.$store.dispatch('IS_LOGOUT')
        .then(response => {
          console.log(response)
          this.$router.push({ path: '/login' })
        }).catch(error => {
          console.log(error)
        })
    },
    closeSuccess (val) {
      this.successAdd = val
      this.logOutStore()
    },
    updateSuccess (val) {
      this.successAdd = val
      this.$store.getters.userInfos.firstLogin = false
    },
    getAllStat () {
      http.post(apiroutes.baseURL + apiroutes.getAllStat, {}, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          console.log(response)
          this.stat = response
          this.loader = this.stat.loader === 0
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .dashboard {
    padding: 2rem 4rem 4rem 1.5rem;
  }
</style>
